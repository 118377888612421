import React, { useEffect } from 'react';
import banner1 from '../../images/Home/banner_1.jpg';
import banner2 from '../../images/Home/banner_2.jpg';
import banner3 from '../../images/Home/banner_3.jpg';
import '../Carousel/carousel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function Carousel() {
    const title = "Special Ventilation Solutions";
    const paragraph = "to the most sensitive sectors";

    useEffect(() => {
        const carouselElement = document.querySelector('#carouselExampleIndicators');
        if (carouselElement) {
            const bootstrap = require('bootstrap');
            new bootstrap.Carousel(carouselElement, {
                interval: 2000,
                ride: 'carousel',
                pause: false,
            });
        }
    }, []);
    return (
        <div className='container-fluid p-0'>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" style={{ marginTop: "65px" }}>
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={banner1} className="d-block w-100" alt="home_banner" />
                        <div className="carousel-caption d-none d-md-block">
                            <div className="bg-text">
                                <h5>{title}</h5>
                                <p>{paragraph}</p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={banner2} className="d-block w-100" alt="home_banner" />
                        <div className="carousel-caption d-none d-md-block">
                            <div className="bg-text">
                                <h5>{title}</h5>
                                <p>{paragraph}</p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={banner3} className="d-block w-100" alt="home_banner" />
                        <div className="carousel-caption d-none d-md-block">
                            <div className="bg-text">
                                <h5>{title}</h5>
                                <p>{paragraph}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default Carousel