import React from 'react'

function TenderText2() {
    return (
        <div>
            <embed src="\pdfs\EAT1.1.2.1_RL_Radial_slot_outlet_kr (1).pdf" width="100%" height="2100px" />
        </div>
    )
}

export default TenderText2