import React from 'react';
import '../Certificates/certificates.css';
import Header from '../../../Components/Header/Header';
import Accordion from '../../../Components/Accordition/Acordition';
import Footer from '../../../Components/Footer/Footer';
import { Helmet } from 'react-helmet';


const certificateSections = [
  {
    title: "Managementsystems",
    content: (
      <>
        <p>Certificate DIN EN ISO 9001<br /></p><hr />
        <p>Certificate BS OHSAS 18001 Krantz</p>
      </>
    )
  },
  {
    title: "Nuclear",
    content: (
      <>
        <p>10 CFR 50 Appendix B<br /></p><hr />
        <p>Confirmation on quality assurance to nuclear standard KTA 1401, Framatome</p>
      </>
    )
  },
  {
    title: "Welding Technology",
    content: (
      <>
        <p>DIN EN ISO 3834-2<br /></p><hr />
        <p>EN 1090-2</p>
      </>
    )
  },
  {
    title: "Miscellaneous",
    content: (
      <>
        <p>VDI 6022 Trained Personnel Categories A & B<br /></p><hr />
        <p>§40 Radiation Protection Ordinance (Personnel with radiation passes)<br /></p><hr />
        <p>§12 Atomic Energy Act (Personnel with background checks / security screening)<br /></p><hr />
        <p>§15 Radiation Protection Ordinance (Authorisation of activities in foreign plants or facilities)</p>
      </>
    )
  }
];

function Certificates() {
  return (
    <>
    <Helmet>
        <title>Nuclear Filters | Iodine Filters</title>
        <meta name="description" content=" " />
        <meta name="robots" content="index, follow" />
        <meta name="geo.placename" content="India" />
        <meta http-equiv="content-language" content="in" />
        <meta name="geo.region" content="india" />
        <meta property="og:title" content="Nuclear Filters | Iodine Filters" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
        <meta property="og:locale" content="en-in" />
        <link rel="alternate" href="https://www.scientechnology.com/certificates" hreflang="en-in" />
        <link rel="canonical" href="https://www.scientechnology.com/certificates" />
      </Helmet>
      <section id='certificates'>
        <Header />
        <div className='container' style={{ width: "75%", margin: "auto", marginTop: "120px" }}>
          <div className='col-md-9 col-sm-12'>
            <h2 className='title'>Certificates</h2>
            <p>Here you can find certificates and admissions of Krantz</p>
            <hr className='certificate_hr' />
            <Accordion sections={certificateSections} />
          </div>
          <hr className='certificate_hr' />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Certificates