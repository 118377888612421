import React, { forwardRef } from 'react';
import '../Products/air-distribut-system/airdistribution.css';

const ProductSection = forwardRef(({ title, products, startIndex, endIndex, handleImageClick }, ref) => (
    <div ref={ref}>
        <h5>{title}</h5>
        <div className='row'>
            {products.slice(startIndex, endIndex).map((product, index) => (
                <div key={index} className='col-md-6'>
                    <img className='img-fluid' src={product.img} alt={product.title} onClick={() => handleImageClick(product.img, product.title, product.content)} />
                    <h6>{product.title}</h6>    
                </div>
            ))}
        </div>
    </div>
));

export default ProductSection;