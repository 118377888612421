import React from 'react'

function TenderText9() {
  return (
    <div>
        <embed src="\pdfs\EAT1.1.5_MD_Microdrall_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default TenderText9