import React from 'react'

function CeilingAirOutlet() {
  return (
    <div>
        <embed src="/pdfs/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default CeilingAirOutlet