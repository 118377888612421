import React from 'react'

function LayoutSpecification6() {
  return (
    <div>
         <embed src="\pdfs\E_TB69_layout-specifications-for-thermal-comfort.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default LayoutSpecification6