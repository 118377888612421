import React from 'react'

function TwistOutlet() {
    return (
        <div>
            <embed src="/pdfs/E1.1.1_DD-N_Twist_outlet_06.2014_kr.pdf" width="100%" height="2100px" />
        </div>
    )
}

export default TwistOutlet