import React, { useState } from 'react'
import '../Contact/contact.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import profile from '../../images/profile.png';
import { Helmet } from 'react-helmet';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    let errorMessage = '';

    switch (id) {
      case 'name':
        if (!/^[A-Za-z\s]*$/.test(value)) {
          errorMessage = 'Enter characters only in Name field';
        } else if (value.trim().length < 3) {
          errorMessage = 'Enter at least 3 characters in Name field';
        }
        break;
      case 'email':
        if (value.trim() !== '' && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
          errorMessage = 'Email address is not valid';
        }
        break;
      case 'phone':
        if (!/^(\+\d{1,3}\s?)?(\d{1,4}[\s.-]?)?(\d{3}[\s.-]?\d{3}[\s.-]?\d{4})$/.test(value)) {
          errorMessage = 'Enter a valid 12-digit phone number';
        }
        break;
      default:
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };




  const handleSubmit = (e) => {
    e.preventDefault();

    const formIsValid = Object.values(errors).every((error) => error === '');

    const requiredFields = ['name', 'email', 'phone', 'message'];
    const emptyFieldErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        emptyFieldErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (formIsValid && Object.keys(emptyFieldErrors).length === 0) {
      const { name, email, phone, message } = formData;
      const subject = encodeURIComponent('Scientechnology Contact Form Submission');
      const body = encodeURIComponent(`Name: ${name} \nEmail: ${email} \nPhone: ${phone} \nMessage: ${message} `);
      const mailtoLink = `mailto:thomas@scientechnology.com?subject=${subject}&body=${body}`;

      window.location.href = mailtoLink;
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });

      setErrors({
        name: '',
        email: '',
        phone: '',
        message: '',
      });

    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...emptyFieldErrors,
      }));
    }
  };

  return (
    <>
      <Helmet>
        <title>Stainless steel HEPA filters | Stainless steel BIBO</title>
        <meta name="description" content=" " />
        <meta name="robots" content="index, follow" />
        <meta name="geo.placename" content="India" />
        <meta http-equiv="content-language" content="in" />
        <meta name="geo.region" content="india" />
        <meta property="og:title" content="Stainless steel HEPA filters | Stainless steel BIBO" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
        <meta property="og:locale" content="en-in" />
        <link rel="alternate" href="https://www.scientechnology.com/contact" hreflang="en-in" />
        <link rel="canonical" href="https://www.scientechnology.com/contact" />
      </Helmet>
      <section id='contact'>
        <Header />
        <div className='container' style={{ width: "75%", margin: "auto", marginTop: "130px" }}>
          <div className='row'>
            <div className='col-md-6'>
              <div style={{ marginTop: "20px" }}>
                <h2 className='contact_title' style={{ marginBottom: "30px" }}>Contact us</h2>
                <h5 className='contact_sub_title'>INDIA</h5>
                <p className='contact_para'>ACOUSTICAL SURFACES PVT LTD</p>
                <span className='contact_address'>
                  No.490/1, 1st Floor,<br />
                  Sri Krishna Rajendra Road,<br />
                  Tyaga Raja Nagar(P.O) - 560028<br />
                  Bangalore, Karnataka, India<br />
                </span>
                <div className='row mt-2 india'>
                  <div className='col-2 col-md-3 col-lg-2'>
                    <img src={profile} alt="profile" style={{ width: "80px", height: "80px", padding: "10px 10px" }} />
                  </div>
                  <div className='col-10 col-md-9 col-lg-10 p-0 m-0'>
                    <p className='contact_address' style={{ margin: "0px" }}>Thomas Philip</p>
                    <p className='contact_address' style={{ margin: "0px" }}>Thomas@scientechnology.com</p>
                    <p className='contact_address' style={{ margin: "0px" }}>Tel: +91 9345913100</p>
                    <p className='contact_address' style={{ margin: "0px" }}>www.scientechnology.com</p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <h5 className='contact_sub_title'>GERMANY</h5>
                <p className='contact_para'>KRANTZ GMBH</p>
                <span className='contact_address'>
                  Uersfeld 24, 52072 Aachen, Germany<br />
                </span>
                <div className='row mt-2 germany'>
                  <div className='col-2 col-md-3 col-lg-2'>
                    <img src={profile} alt="profile" style={{ width: "80px", height: "80px", padding: "10px 10px" }} />
                  </div>
                  <div className='col-10 col-md-9 col-lg-10 p-0 m-0'>
                    <p className='contact_address' style={{ margin: "0px" }}>Marco Sauder</p>
                    <p className='contact_address' style={{ margin: "0px" }}>Marco.Sauder@krantz.de</p>
                    <p className='contact_address' style={{ margin: "0px" }}>Tel: +49 241 441-285</p>
                    <p className='contact_address' style={{ margin: "0px" }}>www.krantz.de</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 contact_form mt-5'>
              <div className="enqform">
                <form onSubmit={handleSubmit} noValidate className="enquiry-form">
                  <h1>SEND YOUR MESSAGE</h1>
                  <div className="form-group">
                    <input
                      placeholder="Your Name"
                      type="text"
                      className="form-control"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.name}</span>
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Your Email"
                      type="email"
                      className="form-control"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.email}</span>
                  </div>

                  <div className="form-group">
                    <input
                      type="phone"
                      className="form-control"
                      id="phone"
                      placeholder="Contact Number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.phone}</span>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="message"
                      rows="6"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    <span className="error-message" style={{ color: "red", fontSize: "15px" }}>{errors.message}</span>
                  </div>

                  <button type="submit" className="form-btn mt-3">Submit</button>
                </form>
              </div>
            </div>
          </div>
          <hr className='contact_hr' />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Contact