import React from 'react'

function TenderText3() {
    return (
        <div>
            <embed src="\pdfs\EAT1.1.2.2_RL-C_Radial_slot_outlet_kr (2).pdf" width="100%" height="2100px" />
        </div>
    )
}

export default TenderText3