import React from 'react'

function VariableTwist10() {
  return (
    <div>
        <embed src="\pdfs\E1.1.6.2_DD-VK_Variable_twist_outlet_with_core_tube_05.2007-wTC_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default VariableTwist10