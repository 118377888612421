import React from 'react';
import '../Accordition/acordition.css';


function Accordion({ sections }) {
    return (
        <div className='container p-0'>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                {sections.map((section, index) => (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`flush-collapse${index}`}>
                                {section.title}
                            </button>
                        </h2>
                        <div
                            id={`flush-collapse${index}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">{section.content}</div>
                        </div>
                    </div>
                ))}
            </div>
            {/* <hr /> */}
        </div>
    );
}

export default Accordion;
