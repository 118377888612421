import React from 'react';
import '../AboutService/about-service.css';
import Header from '../../../Components/Header/Header';
import service1 from '../../../images/about/service1.jpg';
import service2 from '../../../images/about/service2.jpg';
import service3 from '../../../images/about/service3.jpg';
import Footer from '../../../Components/Footer/Footer';
import ScrollToTopButton from '../../../Components/Scrolltotop/Scrolltotop';
import { Helmet } from 'react-helmet';

function AboutService() {
  return (
    <>
      <Helmet>
        <title>Bio safety containment | Biosafety Level 3 | Level 4</title>
        <meta name="description" content=" " />
        <meta name="robots" content="index, follow" />
        <meta name="geo.placename" content="India" />
        <meta http-equiv="content-language" content="in" />
        <meta name="geo.region" content="india" />
        <meta property="og:title" content="Bio safety containment | Biosafety Level 3 | Level 4" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
        <meta property="og:locale" content="en-in" />
        <link rel="alternate" href="https://www.scientechnology.com/about_service" hreflang="en-in" />
        <link rel="canonical" href="https://www.scientechnology.com/about_service" />
      </Helmet>
      <section id='about_service'>
        <Header />
        <div className='container' style={{ width: "75%", marginTop: "100px" }}>
          <div className='col-md-12'>
            <h2 className='about_service_title'>Our Service Areas</h2>
            <hr style={{ width: "100%" }} />
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='service_container'>
                <img className='img-fluid service_image' src={service1} alt="about_service" />
                <h4 className='service_text'>Air Distribution Systems</h4>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='service_container'>
                <img className='img-fluid service_image' src={service2} alt="about_service" />
                <h4 className='service_text'>Cooling and Heating Systems</h4>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='service_container'>
                <img className='img-fluid service_image' src={service3} alt="about_service" />
                <h4 className='service_text'>Filter and Damping Systems</h4>
              </div>
            </div>
          </div>
          <hr className='hr_bottom' />
        </div>
      </section>
      <Footer />
      <ScrollToTopButton />
    </>
  )
}

export default AboutService