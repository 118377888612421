import React from 'react';
import '../Footer/footer.css';
import { Link } from 'react-router-dom';
import ScrollToTopButton from '../Scrolltotop/Scrolltotop';

function Footer() {
    const whatsAppUrl = "https://wa.me/9345913100?text=Thank you for contacting SCIENTECHNOLOGY!!%0A%0APlease let us know how we can assist on your requirements.";
    return (
        <>
            <div className="container footer-container">
                <div className="footer-content">
                    <Link to='/contact' className="footer-link">CONTACT</Link>
                    <Link to='/privacy' className="footer-link">PRIVACY STATEMENT</Link>
                    <Link to='/disclaimer' className="footer-link">DISCLAIMER</Link>
                    <p className="footer-text">© 2024 | SCIENTECHNOLOGY.COM</p>
                    <a className='wh-me mt-2' href={whatsAppUrl} target="_blank" rel="noopener noreferrer">
                        <img src="whtup.gif" alt="WhatsApp" style={{ width: '40px', height: "40px", borderRadius: "30%" }} />
                        <p className='whup-num'>+91 9345913100</p>
                    </a>
                </div>
            </div>
            <ScrollToTopButton />
        </>
    )
}

export default Footer