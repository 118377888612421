import React, { useEffect } from 'react';
import '../Header/header.css';
import logo from '../../images/logo.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.targetId) {
      setTimeout(() => {
        handleScroll(location.state.targetId);
      }, 300);
    }
  }, [location]);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const navbarHeight = document.querySelector('nav').offsetHeight;
      const offset = 10;
      window.scrollTo({
        top: element.offsetTop - navbarHeight - offset,
        behavior: 'smooth',
      });
    } else {
      console.warn(`Element with ID ${id} not found.`);
    }
  };

  const navigateToSection = (id) => {
    if (location.pathname === '/air_distribution') {
      setTimeout(() => {
        handleScroll(id);
      }, 300)
    } else {
      navigate('/air_distribution', { state: { targetId: id } });
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand me-auto" to="/">
            <img src={logo} alt="logo" /></Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>CLOSE
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
                <li className="nav-item">
                  <Link className="nav-link mx-lg-2 active" aria-current="page" to="/">HOME</Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle mx-lg-2" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ABOUT
                  </Link>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item mx-lg-2" to="/about_profile">PROFILE AND HISTORY</Link></li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li><Link className="dropdown-item mx-lg-2" to="/about_service">SERVICE AREAS</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle mx-lg-2" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    PRODUCTS
                  </Link>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item mx-lg-2" to="/air_distribution" onClick={() => navigateToSection('air_distribution')}>AIR DISTRIBUTION SYSTEMS</Link></li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="dropdown-item mx-lg-2" onClick={() => navigateToSection('cooling-heating')} style={{ cursor: "pointer" }}>COOLING AND HEATING SYSTEMS</li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="dropdown-item mx-lg-2" onClick={() => navigateToSection('filter-damper')} style={{ cursor: "pointer" }}>FILTER AND DAMPER SYSTEMS</li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li><Link className="dropdown-item mx-lg-2" to="/highintegrity" target='blank'>HIGH INTEGRITY PRODUCTS</Link></li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link className="nav-link mx-lg-2" to="/news">NEWS / EVENT</Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle mx-lg-2" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    DOWNLOADS
                  </Link>
                  <ul className="dropdown-menu border-none">
                    <li><Link className="dropdown-item mx-lg-2" to="/certificates">CERTIFICATES</Link></li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li><Link className="dropdown-item mx-lg-2" to="/technical_articles">TECHNICAL ARTICLES</Link></li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li><Link className="dropdown-item mx-lg-2" to="/brochures  ">BROCHURES</Link></li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li><Link className="dropdown-item mx-lg-2" to="/vedio">VIDEO</Link></li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link className="nav-link mx-lg-2" to="/contact">CONTACT</Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header