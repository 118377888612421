import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import ScrollToTop from './Components/ScrollToTop';
import Home from './Pages/Home/Home';
import News from './Pages/News/News';
import Contact from './Pages/Contact/Contact';
import References from './Pages/References/References';
import Downloads from './Pages/Downloads/Downloads';
import AboutProfile from './Pages/About/Profild&history/About-Profile';
import AboutService from './Pages/About/AboutService/About-Service';
import AirDistribution from './Pages/Products/air-distribut-system/AirDistribution';
import Certificates from './Pages/Downloads/Certificates/Certificates';
import TechnicalArticles from './Pages/Downloads/TechnicalArticle/TechnicalArticles';
import Privacy from './Components/Privacy/Privacy';
import Disclaimer from './Components/Disclaimer/Disclaimer';
import Brochures from './Pages/Downloads/Brochures/Brochures';
import Vedio from './Pages/Downloads/Vedio/Vedio';
import HighIntegrity from './Pages/Products/High_integrity/HighIntegrity';
import ProductSection from './Pages/Products/ProductSection';
import CeilingAirOutlet from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.1/CeilingAirOutlet';
import TwistOutlet from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.1/TwistOutlet';
import TenderText from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.1/TenderText';
import LayoutSpecification from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.1/LayoutSpecification';
import RadialSlot from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.2.1/RadialSlot';
import TenderText2 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.2.1/TenderText2';
import RadialSlot3 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.2.2/RadialSlot3';
import TenderText3 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.2.2/TenderText3';
import RadialOutlet from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.3.1/RadialOutlet';
import TenderText4 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.3.1/TenderText4';
import RadialOutlet5 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.3.2/RadialOutlet5';
import TenderText5 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.3.2/TenderText5';
import RadialOutlet6 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.3.3/RadialOutlet6';
import TenderText6 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.3.3/TenderText6';
import LayoutSpecification6 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.3.3/LayoutSpecification6';
import AdjustableRadial from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.4.1/AdjustableRadial';
import TenderText7 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.4.1/TenderText7';
import CoreTube from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.4.2/CoreTube';
import TenderText8 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.4.2/TenderText8';
import MicrodrallMd from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.5/MicrodrallMd';
import TenderText9 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.5/TenderText9';
import VariableTwist10 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.6.2/VariableTwist10';
import TenderText10 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.6.2/TenderText10';
import TwistOutlet11 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.6.3/TwistOutlet11';
import TenderText11 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.6.3/TenderText11';
import TwistOutletDDVG from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.6.4/TwistOutletDDVG';
import TenderTextDDVG from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.6.4/TenderTextDDVG';
import InductionINN6 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.8/InductionINN6';
import TenderTextINN6 from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.8/TenderTextINN6';
import InductionOutletINV from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.9/InductionOutletINV';
import TenderTextINV from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.9/TenderTextINV';
import OpticleanInstallation from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.10.1/OpticleanInstallation';
import Opticlean from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.10.1/Opticlean';
import TenderOpticlean from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.10.1/TenderOpticlean';
import CircularOpticlean from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.10.2/CircularOpticlean';
import TenderCircularOpticlean from './Pages/Products/popup_pdf/Ceiling-Air-Outlets/product-1.10.2/TenderCircularOpticlean';
import MultuplexOutlet from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product2.1.1/MultuplexOutlet';
import TenderTextCombined from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.1.2/TenderTextCombined';
import TenderTextLinear from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.2/TenderTextLinear';
import TenderTextSwivel from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.3.1/TenderTextSwivel';
import TendertextNozzle from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.3.2/TendertextNozzle';
import TenderTextJetnozzle from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.3.3/TenderTextJetnozzle';
import TenderTextSwiveljet from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.4/TenderTextSwiveljet';
import TenderTextWindow from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.5/TenderTextWindow';
import TenderTextBroad from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.6/TenderTextBroad';
import TenderTextParapet from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.7/TenderTextParapet';
import TenderTextWallslot from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product-2.8/TenderTextWallslot';
import TenderTextCrosstalk from './Pages/Products/popup_pdf/Sidewall-Air-Outlets/product2.9.1-2.9.3/TenderTextCrosstalk';
import TenderTextFloor from './Pages/Products/popup_pdf/Floor-Air-Outlets/product-3.1/TenderTextFloor';
import TenderTextRotary from './Pages/Products/popup_pdf/Floor-Air-Outlets/product-3.2/TenderTextRotary';
import TenderTextDN from './Pages/Products/popup_pdf/Floor-Air-Outlets/product-3.3/TenderTextDN';
import TenderTextNTK from './Pages/Products/popup_pdf/Floor-Air-Outlets/product-3.4/TenderTextNTK';
import TenderTextFlordisp from './Pages/Products/popup_pdf/Floor-Air-Outlets/product-3.6/TenderTextFlordisp';
import TenderTextRectangular from './Pages/Products/popup_pdf/Commercial-Sector/product-4.1/TenderTextRectangular';
import TenderTextCircul from './Pages/Products/popup_pdf/Commercial-Sector/product-4.2.1/TenderTextCircul';
import TenderTextSemi from './Pages/Products/popup_pdf/Commercial-Sector/product-4.2.2/TenderTextSemi';
import TenderTextWall from './Pages/Products/popup_pdf/Commercial-Sector/product-4.5/TenderTextWall';
import TenderTextCeiling from './Pages/Products/popup_pdf/Commercial-Sector/product-4.6/TenderTextCeiling';
import TenderTextAmper from './Pages/Products/popup_pdf/Industrial-Sector/product-5.1/TenderTextAmper';
import TenderTextVARV from './Pages/Products/popup_pdf/Industrial-Sector/product-5.2/TenderTextVARV';
import TenderTextTrapezodial from './Pages/Products/popup_pdf/Industrial-Sector/product-5.3.1/TenderTextTrapezodial';
import TenderTextSemiTrap from './Pages/Products/popup_pdf/Industrial-Sector/product-5.3.2/TenderTextSemiTrap';
import TenderTextSwivelVAS from './Pages/Products/popup_pdf/Industrial-Sector/product-5.4/TenderTextSwivelVAS';
import TenderTextRadialVAVP from './Pages/Products/popup_pdf/Industrial-Sector/product-5.5/TenderTextRadialVAVP';
import TenderTextVAK from './Pages/Products/popup_pdf/Industrial-Sector/product-5.6/TenderTextVAK';
import TenderTextVARSA from './Pages/Products/popup_pdf/Industrial-Sector/product-5.8/TenderTextVARSA';
import TenderTextQSTSeat from './Pages/Products/popup_pdf/Assembly-Rooms/product-6.2/TenderTextQSTSeat';
import TenderTextQSRQSL from './Pages/Products/popup_pdf/Assembly-Rooms/product-6.3/TenderTextQSRQSL';
import TenderTextDS from './Pages/Products/popup_pdf/Assembly-Rooms/product-6.4.1/TenderTextDS';
import TenderTextDSRADN from './Pages/Products/popup_pdf/Assembly-Rooms/product-1.6.4.2/TenderTextDSRADN';
import TenderTextSDL from './Pages/Products/popup_pdf/Assembly-Rooms/product-6.4.3/TenderTextSDL';
import TenderTextVRIDN from './Pages/Products/product-7.1/TenderTextVRIDN';
import TenderTextCooling from './Pages/Products/popup_pdf/High-capacity-Ceiling-Systems/product-1.1.1/TenderTextCooling';
import TenderTextSKS from './Pages/Products/popup_pdf/High-capacity-Ceiling-Systems/product-1.1.2/TenderTextSKS';
import TenderTextSKSStatic from './Pages/Products/popup_pdf/High-capacity-Ceiling-Systems/product-1.2/TenderTextSKSStatic';
import TenderTextKKS from './Pages/Products/popup_pdf/Metal-Gypsum-board/product-2.1.1/TenderTextKKS';
import TenderTextKKSLDM from './Pages/Products/popup_pdf/Metal-Gypsum-board/product-2.1.2/TenderTextKKSLDM';
import TenderTextKKSG from './Pages/Products/popup_pdf/Metal-Gypsum-board/product-2.2/TenderTextKKSG';
import TenderTextPutz from './Pages/Products/popup_pdf/Metal-Gypsum-board/product-2.3/TenderTextPutz';
import TenderTextAVACS from './Pages/Products/popup_pdf/Chilled-Sails/product-3.2.1/TenderTextAVACS';
import TenderTextTwoside from './Pages/Products/popup_pdf/Chilled-Beams/product-4.2.1/TenderTextTwoside';
import TenderTextDKZ from './Pages/Products/popup_pdf/Chilled-Beams/product-4.2.1.1/TenderTextDKZ';
import TenderTextVentilation from './Pages/Products/popup_pdf/Chilled-Beams/product-4.2.2/TenderTextVentilation';
import TenderTextLGZAMSB from './Pages/Products/popup_pdf/Facade-Floor-Mounted-Systems/product-5.1.3/TenderTextLGZAMSB';
import TenderTextIGMSB from './Pages/Products/popup_pdf/Facade-Floor-Mounted-Systems/product-5.1.7/TenderTextIGMSB';
import TenderTextIGQSB from './Pages/Products/popup_pdf/Facade-Floor-Mounted-Systems/product-5.1.8/TenderTextIGQSB';
import TenderTextLGZMLB from './Pages/Products/popup_pdf/Facade-Floor-Mounted-Systems/product-5.2.1/TenderTextLGZMLB';
import TenderTextLGUMMLB from './Pages/Products/popup_pdf/Facade-Floor-Mounted-Systems/product-5.2.2/TenderTextLGUMMLB';
import TenderTextIGMLB from './Pages/Products/popup_pdf/Facade-Floor-Mounted-Systems/product-5.2.3/TenderTextIGMLB';
import TenderTextSCF from './Pages/Products/popup_pdf/Hepa-Filter-Systems/product-1.1.1/TenderTextSCF';
import TenderTextSCFHightec from './Pages/Products/popup_pdf/Hepa-Filter-Systems/product-1.1.2/TenderTextSCFHightec';
import TenderTextSCFClassic from './Pages/Products/popup_pdf/Hepa-Filter-Systems/product-1.1.3/TenderTextSCFClassic';
import TenderTextGsbox from './Pages/Products/popup_pdf/Hepa-Filter-Systems/product-1.2/TenderTextGsbox';
import TenderTextPuri from './Pages/Products/popup_pdf/Hepa-Filter-Systems/product-1.5.2/TenderTextPuri';
import TenderTextWFZ from './Pages/Products/popup_pdf/Hepa-Filter-Systems/product-1.8/TenderTextWFZ';
import TenderTextGaslight from './Pages/Products/popup_pdf/Dampers/product-2.1.1/TenderTextGaslight';
import TenderTextGDR from './Pages/Products/popup_pdf/Dampers/product-2.1.2/TenderTextGDR';
import TenderTextDamperS from './Pages/Products/popup_pdf/Dampers/product-2.1.3/TenderTextDamperS';
import TenderTextKLE from './Pages/Products/popup_pdf/Dampers/product-2.2.1/TenderTextKLE';
import TenderTextKLEEM from './Pages/Products/popup_pdf/Dampers/product-2.2.2/TenderTextKLEEM';
import TenderTextKLETEETM from './Pages/Products/popup_pdf/Dampers/product-2.2.3/TenderTextKLETEETM';
import TenderTextPressureRelief from './Pages/Products/popup_pdf/Dampers/product-2.2.4/TenderTextPressureRelief';
import TenderTextLouver from './Pages/Products/popup_pdf/Dampers/Product-2.3.1/TenderTextLouver';
import TenderTextNDJ from './Pages/Products/popup_pdf/Dampers/product-2.3.2/TenderTextNDJ';
import TenderTextNonReturn from './Pages/Products/popup_pdf/Dampers/product-2.4/TenderTextNonReturn';
import TenderTextRKF from './Pages/Products/popup_pdf/Dampers/product-2.5/TenderTextRKF';
import TenderTextSonder from './Pages/Products/popup_pdf/Accessory-Miscellaneous/product-3.1.1.3.1.2/TenderTextSonder';
import TenderTextLTD from './Pages/Products/popup_pdf/Accessory-Miscellaneous/product-3.3/TenderTextLTD';
import TenderTextMDC from './Pages/Products/popup_pdf/Accessory-Miscellaneous/product-3.8/TenderTextMDC';


function App() {
  return (
    <div className="App">

      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/references" element={<References />} />
          <Route path="/about_profile" element={<AboutProfile />} />
          <Route path="/about_service" element={<AboutService />} />
          <Route path="/air_distribution" element={<AirDistribution />} />
          <Route path="/product_section" element={<ProductSection />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/technical_articles" element={<TechnicalArticles />} />
          <Route path="/brochures" element={<Brochures />} />
          <Route path="/vedio" element={<Vedio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />

          <Route path="/highintegrity" element={<HighIntegrity />} />
          <Route path="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" element={<CeilingAirOutlet />} />
          <Route path="/E1.1.1_DD-N_Twist_outlet_06.2014_kr.pdf" element={<TwistOutlet />} />
          <Route path="/EAT1.1.1_DD-N_Twist_outlet_kr.pdf" element={<TenderText />} />
          <Route path="/layout-specifications-for-thermal-comfort-.pdf" element={<LayoutSpecification />} />
          <Route path="/E1.1.2.1_RL_Radial_slot_outlet_09.2014-1_kr.pdf" element={<RadialSlot />} />
          <Route path="/EAT1.1.2.1_RL_Radial_slot_outlet_kr (1).pdf" element={<TenderText2 />} />
          <Route path="/E1.1.2.2_RL-C_Radial_slot_outlet_01.2018-kr.pdf" element={<RadialSlot3 />} />
          <Route path="/EAT1.1.2.2_RL-C_Radial_slot_outlet_kr (2).pdf" element={<TenderText3 />} />
          <Route path="/E1.1.3.1_RA-N_Radial_outlet_02.2016_kr.pdf" element={<RadialOutlet />} />
          <Route path="/EAT1.1.3.1_RA-N_Radial_outlet_kr.pdf" element={<TenderText4 />} />
          <Route path="/E1.1.3.2_RA-N2_Radial_outlet_04.2013_kr.pdf" element={<RadialOutlet5 />} />
          <Route path="/EAT1.1.3.2_RA-N2_Radial_outlet_kr (1).pdf" element={<TenderText5 />} />
          <Route path="/E1.1.3.3_RA-N3_Radial_outlet_04.2013_kr.pdf" element={<RadialOutlet6 />} />
          <Route path="/EAT1.1.3.3_RA-N3_Radial_outlet_kr.pdf" element={<TenderText6 />} />
          <Route path="/E_TB69_layout-specifications-for-thermal-comfort.pdf" element={<LayoutSpecification6 />} />
          <Route path="/E1.1.4.1_RA-V_Adjustable_radial_outlet_04.2013_kr.pdf" element={<AdjustableRadial />} />
          <Route path="/EAT1.1.4.1_RA-V_Adjustable_radial_outlet_kr.pdf" element={<TenderText7 />} />
          <Route path="/E1.1.4.2_RA-V2_Adjustable_radial_outlet_with_core_tube_01.2014_kr.pdf" element={<CoreTube />} />
          <Route path="/EAT1.1.4.2_RA-V2_Adjustable_radial_outlet_with_core_tube_kr (1).pdf" element={<TenderText8 />} />
          <Route path="/E1.1.5_MD_Microdrall_05.2014_kr.pdf" element={<MicrodrallMd />} />
          <Route path="/EAT1.1.5_MD_Microdrall_kr.pdf" element={<TenderText9 />} />
          <Route path="/E1.1.6.2_DD-VK_Variable_twist_outlet_with_core_tube_05.2007-wTC_kr.pdf" element={<VariableTwist10 />} />
          <Route path="/EAT1.1.6.2_DD-VK_Variable_twist_outlet_with_core_tube_kr.pdf" element={<TenderText10 />} />
          <Route path="/E1.1.6.3_DD-VL_Variable_twist_outlet-guide_ring_01.2018-kr.pdf" element={<TwistOutlet11 />} />
          <Route path="/EAT1.1.6.3_DD-VL_Variable_twist_outlet_with_guide_ring_2018_kr.pdf" element={<TenderText11 />} />
          <Route path="/E1.1.6.4_DD-VG_Variable_twist_outlet-jet_streigtener_01.2018-kr.pdf" element={<TwistOutletDDVG />} />
          <Route path="/EAT1.1.6.4_DD-VG_Variable_twist_outlet_with_jet_straightener_2018_kr.pdf" element={<TenderTextDDVG />} />
          <Route path="/E1.1.8_IN-N6_Induction_outlet_with_preset_discharge_direction_04.2013_kr.pdf" element={<InductionINN6 />} />
          <Route path="/EAT1.1.8_IN-N_Induction_outlet_with_preset_discharge_direction_kr.pdf" element={<TenderTextINN6 />} />
          <Route path="/E1.1.9_IN-V_Adjustable_induction_outlet_04.2013_kr.pdf" element={<InductionOutletINV />} />
          <Route path="/EAT1.1.9_IN-V_Adjustable_induction_outlet_kr (1).pdf" element={<TenderTextINV />} />
          <Route path="/E_1.10.1_K250e_Opticlean_Metal_ceiling_12.2018.pdf" element={<OpticleanInstallation />} />
          <Route path="/E1.1.10.1_OC-Q_Opticlean_06.2013-1_kr.pdf" element={<Opticlean />} />
          <Route path="/EAT1.1.10.1_OC-Q_Opticlean_kr (1).pdf" element={<TenderOpticlean />} />
          <Route path="/E1.1.10.2_OC-R_Circular_Opticlean_01.2018-kr.pdf" element={<CircularOpticlean />} />
          <Route path="/EAT1.1.10.2_OC-R_Circular_Opticlean_kr.pdf" element={<TenderCircularOpticlean />} />
          <Route path="/EAT1.2.1.1_FA-V_Multiplex_outlet_kr (1).pdf" element={<MultuplexOutlet />} />
          <Route path="/EAT1.2.1.2_FA-VK_Combined_multiplex_outlet_kr.pdf" element={<TenderTextCombined />} />
          <Route path="/EAT1.2.2_WL_Linear_whirl_outlet_kr.pdf" element={<TenderTextLinear />} />
          <Route path="/EAT1.2.3.1_DW-V2_Swivel_jet_nozzle_kr.pdf" element={<TenderTextSwivel />} />
          <Route path="/EAT1.2.3.2_DW-V2-_-DR_Twist_nozzle_kr.pdf" element={<TendertextNozzle />} />
          <Route path="/EAT1.2.3.3_DW-N2_Jet_nozzle_kr.pdf" element={<TenderTextJetnozzle />} />
          <Route path="/EAT1.2.4_SW_Swivel_jet_outlet_kr.pdf" element={<TenderTextSwiveljet />} />
          <Route path="/EAT1.2.5_FSG_Window_air_curtain_unit_kr.pdf" element={<TenderTextWindow />} />
          <Route path="/EAT1.2.6_BF-V_Broad_multiplex_outlet_kr.pdf" element={<TenderTextBroad />} />
          <Route path="/EAT1.2.7_BL-V_Parapet_outlet_kr.pdf" element={<TenderTextParapet />} />
          <Route path="/EAT1.2.8_WSD_Wall_slot_diffuser_kr.pdf" element={<TenderTextWallslot />} />
          <Route path="/EAT1.2.9_OG_Crosstalk-attenuation-air-transfer-element_kr.pdf" element={<TenderTextCrosstalk />} />

          <Route path="/EAT1.3.1_DB-E-DN_Floor_twist_outlets_kr.pdf" element={<TenderTextFloor />} />
          <Route path="/EAT1.3.2_DB-D-DN_Rotary_floor_twist_outlet_kr.pdf" element={<TenderTextRotary />} />
          <Route path="/EAT1.3.3_BA-V-DN150_Adjustable_floor_outlet_kr.pdf" element={<TenderTextDN />} />
          <Route path="/EAT1.3.4_DB-N_NTK-floor_twist_outlet_kr.pdf" element={<TenderTextNTK />} />
          <Route path="/EAT1.3.6_Q-B-DN200_Floor_displacement_outlet_kr.pdf" element={<TenderTextFlordisp />} />
          <Route path="/EAT1.3.7_Q-B-DN215_Floor_displacement_outlet_kr.pdf" element={<TenderText />} />

          <Route path="/EAT1.4.1_Q-R_Rectangular_displacement_outlet_kr.pdf" element={<TenderTextRectangular />} />
          <Route path="/EAT1.4.2.1_Q-Z_Circular_displacement_outlet_kr.pdf" element={<TenderTextCircul />} />
          <Route path="/EAT1.4.2.2_Q-ZH_Semi-circular_displacement_outlet_kr.pdf" element={<TenderTextSemi />} />
          <Route path="/EAT1.4.5_Q-W__Wall_displacement_outlet_kr.pdf" element={<TenderTextWall />} />
          <Route path="/EAT1.4.6_Q-DN_Q-DV_Ceiling_displacement_outlet_kr.pdf" element={<TenderTextCeiling />} />
          <Route path="/EAT2.5.2.4_Q-BR-Rectangular_floor_displacement_outlet_kr.pdf" element={<TenderTextFloor />} />

          <Route path="/EAT1.5.1.1_VA-ZD_Circular_displacement_outlet_with_adjustabledamper_040.2018_neu.pdf" element={<TenderTextAmper />} />
          <Route path="/EAT1.5.2_VA-RV_VA-RN_Rectangular_displacement_outlet_kr.pdf" element={<TenderTextVARV />} />
          <Route path="/EAT1.5.3.1_VA-T_Trapezoidal_displacement_outlet_01.2018_kr.pdf" element={<TenderTextTrapezodial />} />
          <Route path="/EAT1.5.3.2_VA-TH_Semi-trapezoidal_displacement_outlet_01.2018_kr.pdf" element={<TenderTextSemiTrap />} />
          <Route path="/EAT1.5.4_VA-S_Swivel_displacement_outlet_kr.pdf" element={<TenderTextSwivelVAS />} />
          <Route path="/EAT1.5.5_VA-PV_Radial_displacement_outlet_kr.pdf" element={<TenderTextRadialVAVP />} />
          <Route path="/EAT1.5.6_VA-K_Conical_displacement_outlet_kr.pdf" element={<TenderTextVAK />} />
          <Route path="/EAT1.5.8.1_VA-RSA_Displacement_outlet_for_indoor_firing_ranges_kr.pdf" element={<TenderTextVARSA />} />

          <Route path="/EAT1.6.2_Q-ST_Seat_displacement_outlet_kr.pdf" element={<TenderTextQSTSeat />} />
          <Route path="/EAT1.6.3_Q-SR-Q-SL_Step_displacement_outlets_kr.pdf" element={<TenderTextQSRQSL />} />
          <Route path="/EAT1.6.4.1_DS_Step_twist_outlet_kr.pdf" element={<TenderTextDS />} />
          <Route path="/EAT1.6.4.2_DS-RA-DN80_Step_twist_outlet_kr.pdf" element={<TenderTextDSRADN />} />
          <Route path="/EAT1.6.4.3_SD-L_Linear_Step_twist_outlet_kr.pdf" element={<TenderTextSDL />} />

          <Route path="/EAT1.7.1_VRI-DN_Volume_flow_controller_kr.pdf" element={<TenderTextVRIDN />} />

          <Route path="/EAT2.1.1.1_SKS-4-3_Static_Cooling_Ceiling_System_kr.pdf" element={<TenderTextCooling />} />
          <Route path="/EAT2.1.1.2_SKS-4-3-duo_Static_Cooling_Ceiling_System_kr.pdf" element={<TenderTextSKS />} />
          <Route path="/EAT2.1.2_SKS-5-3_Static_Cooling_System.pdf" element={<TenderTextSKSStatic />} />

          <Route path="/EAT2.1.1_KKS-3LD_Contact_cooling_ceiling_system_Krantz.pdf" element={<TenderTextKKS />} />
          <Route path="/DAT2.2.1.2_KKS-5-LD-M_Kontakt-Kuehldeckensystem_kr.pdf" element={<TenderTextKKSLDM />} />
          <Route path="/EAT2.2.2_KKS-4-GK_Contact_cooling_ceiling_system_kr.pdf" element={<TenderTextKKSG />} />
          <Route path="/DAT2.2.3_PKS_Putz-Kuehldeckensystem_kr.pdf" element={<TenderTextPutz />} />

          <Route path="/EAT2.3.1_AVACS_Multifunction_sail_kr.pdf" element={<TenderTextAVACS />} />

          <Route path="/DK-LIG-Z_Ausschreibungstext_2011-03.pdf" element={<TenderTextTwoside />} />
          <Route path="/EAT2.4.2_DK-LIG-Z-600_Chilled_beam_kr.pdf" element={<TenderTextDKZ />} />
          <Route path="/DAT2.4.2_DK-LIG_Aktiver_Deckenkuehlkonvektor_kr.pdf" element={<TenderTextVentilation />} />

          <Route path="/DAT2.5.1.3_LG-ZA-M-SB_Dezentrales_Lueftungsgeraet_kr.pdf" element={<TenderTextLGZAMSB />} />
          <Route path="/EAT2.5.1.7_IG-M-SB_Induction_unit_for_mixing_ventilation_for_vertical_parapet_mounting_kr_01.pdf" element={<TenderTextIGMSB />} />
          <Route path="/EAT2.5.1.8_IG-Q-SB_Induction_unit_for_displacement_ventilation_for_vertical_parapet_mounting_kr.pdf" element={<TenderTextIGQSB />} />
          <Route path="/DAT2.5.2.1_LG-Z-M-LB_LG-ZUM-M-LB_Dezentrales_Lueftungsgeraet_fuer_Mischlueftung-liegend_kr.pdf" element={<TenderTextLGZMLB />} />
          <Route path="/DAT2.5.2.2_LG-UM-M-LB_Dezentrales_Lueftungsgeraet_fuer_Mischlueftung-liegend_kr_NEU.pdf" element={<TenderTextLGUMMLB />} />
          <Route path="/EAT2.5.2.3_IG-M-LB_Induction_unit_for_mixing_ventilation_for_horizontal_floor_mounting_kr.pdf" element={<TenderTextIGMLB />} />

          <Route path="/EA1.1.1_scf-hightec-triple-s_text_for_tender_08.2013-kr.pdf" element={<TenderTextSCF />} />
          <Route path="/EA1.1.2_scf-hightec_08.2013-kr.pdf" element={<TenderTextSCFHightec />} />
          <Route path="/EA1.1.3_scf-classic_08.2013-kr.pdf" element={<TenderTextSCFClassic />} />
          <Route path="/EA1.2_gs-box_08.2013-kr.pdf" element={<TenderTextGsbox />} />
          <Route path="/E1.5.2_puri-drall-pdk_puri-clean-pck_puri-inlet-pek_01.2013-kr.pdf" element={<TenderTextPuri />} />
          <Route path="/EA1.8_wfz_08.2013-kr.pdf" element={<TenderTextWFZ />} />

          <Route path="/EA2.1.1_gd-c_08.2013-kr.pdf" element={<TenderTextGaslight />} />
          <Route path="/EA2.1.2_gd-r_08.2013-kr.pdf" element={<TenderTextGDR />} />
          <Route path="/EA2.1.3_s-klappe_08.2013-kr.pdf" element={<TenderTextDamperS />} />
          <Route path="/EA2.2.1-2_kl-e_em_08.2013-kr.pdf" element={<TenderTextKLE />} />
          <Route path="/EA2.2.1-2_kl-e_em_08.2013-kr.pdf" element={<TenderTextKLEEM />} />
          <Route path="/EA2.2.3-4_kl-ete_etm_08.2013-kr.pdf" element={<TenderTextKLETEETM />} />
          <Route path="/EA2.2.3-4_kl-ete_etm_08.2013-kr.pdf" element={<TenderTextPressureRelief />} />
          <Route path="/EA2.3.1_ld-j_08.2013-kr.pdf" element={<TenderTextLouver />} />
          <Route path="/EA2.3.2_nd-j_08.2013-kr.pdf" element={<TenderTextNDJ />} />
          <Route path="/EA2.4_rk-e20_08.2013-kr.pdf" element={<TenderTextNonReturn />} />
          <Route path="/EA2.5_rk-f10_08.2013-kr.pdf" element={<TenderTextRKF />} />

          <Route path="/EA3.1.1-2_hepa-filter_h13_h14_h14-sonder_08.2013-kr.pdf" element={<TenderTextSonder />} />          
          <Route path="/EA3.3_ltd_08.2013-kr.pdf" element={<TenderTextLTD />} />          
          <Route path="/EA3.8_desiccant-dehumidifier-mdc_2015-kr.pdf" element={<TenderTextMDC />} />          

        </Routes>
      </Router>
    </div>
  );
}

export default App;
