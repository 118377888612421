import React from 'react'

function LayoutSpecification() {
    return (
        <div>
            <embed src="\pdfs\layout-specifications-for-thermal-comfort-.pdf" width="100%" height="2100px" />
        </div>
    )
}

export default LayoutSpecification