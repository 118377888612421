import React from 'react'
import Header from '../../../Components/Header/Header'
import Accordion from '../../../Components/Accordition/Acordition';
import Footer from '../../../Components/Footer/Footer';
import ScrollToTopButton from '../../../Components/Scrolltotop/Scrolltotop';
import '../Brochures/brochures.css'
import { Helmet } from 'react-helmet';

const brochuresSections = [
    {
        title: "Product catalogue",
        content: <p>Krantz Product catalogue 2017<br /></p>
    },
    {
        title: "Imagebrochures",
        content: (
            <>
                <p>Engineering services at a glance<br /></p><hr />
                <p>Multifunction Exposed Ceiling Solutions with System</p><hr />
                <p>Ceiling air outlets Imagebrochure</p><hr />
                <p>Opticlean for installation in metal ceilings</p><hr />
                <p>Floor air outlets Imagebrochure</p><hr />
                <p>Filter systems and dampers Overview brochure</p><hr />
                <p>Sidewall air outlets Imagebrochure</p><hr />
                <p>Displacement air outlets Imagebrochure</p><hr />
                <p>Displacement air outlets for the industrial sector Imagebrochure</p><hr />
                <p>Air distribution systems for assembly rooms Imagebrochure</p><hr />
                <p>HVAC systems for hospitals</p><hr />
                <p>Layout specifications for thermal comfort</p><hr />
                <p>Heating and cooling with ceilings</p>
            </>
        )
    },
    {
        title: "Clean Air Solutions",
        content: (
            <>
                <p>German - Krantz Abluftreinigung Übersichtsbroschüre<br /></p><hr />
                <p>German - Fragebogen für Ihre Projektdaten</p><hr />
                <p>English - Krantz Clean Air Solutions Overview Brochure</p><hr />
                <p>Produktblatt - Krantz Abluftreinigung OxiVOC</p><hr />
                <p>English - Questionnaire for your design data</p><hr />
                <p>Productsheet - Krantz Clean Air Solutions OxiVOC</p><hr />
                <p>Finnish - Krantz Termiset poistoilman puhdistuslaitteet</p><hr />
                <p>French - Questionnaire sur la purification d'air</p><hr />
                <p>Spanish - Krantz Plantas para depuración térmica de humos</p><hr />
                <p>French - Krantz Purification de Air</p><hr />
                <p>Hungarian - Krantz Termikus véggáztisztító berendezések</p><hr />
                <p>Portuguese - Krantz Instalações para depuração térmica de Fumos</p><hr />
                <p>Italian - Krantz depurazione termica</p><hr />
                <p>Dutch - Krantz reinigen van afvoerlucht</p><hr />
                <p>Polish - Krantz termicznego oczyszania powietrza usuwanego</p><hr />
                <p>Swedish - Krantz Termiska frånlufts reningsanläggningar</p><hr />
                <p>Russian - Krantz Установки по ермической очистке отходящих газов</p><hr />
                <p>Krantz Abluftreinigung Ganzheitliche Konzepte</p><hr />
                <p>Krantz Clean Air Solutions Integrated Concepts</p><hr />
                <p>Czech - Krantz Zařízení pro termické čištění odpadních plynů</p><hr />
                <p>Chinese - Krantz 高效废气净化装置</p>
            </>
        )
    },
];

function Brochures() {
    return (
        <>
            <Helmet>
                <title>Air Tight Dampers | Safe change filter system</title>
                <meta name="description" content=" " />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Air Tight Dampers | Safe change filter system" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.scientechnology.com/brochures" hreflang="en-in" />
                <link rel="canonical" href="https://www.scientechnology.com/brochures" />
            </Helmet>
            <section id='brochures'>
                <Header />
                <div className='container' style={{ width: "75%", margin: "auto", marginTop: "100px" }}>
                    <div className='col-md-9 col-sm-12'>
                        <h2 className='brochure_title'>Brochures of Krantz Products </h2>
                        <p className='brochure_para'>Here you can find our product catalogue and downloadable imagebrochures of Krantz Products.</p>
                        <Accordion sections={brochuresSections} />
                    </div>
                    <hr />
                </div>
            </section>
            <Footer />
            <ScrollToTopButton />
        </>
    )
}

export default Brochures