import React from 'react'

function RadialSlot() {
    return (
        <div>
            <embed src="\pdfs\E1.1.2.1_RL_Radial_slot_outlet_09.2014-1_kr.pdf" width="100%" height="2100px" />
        </div>
    )
}

export default RadialSlot