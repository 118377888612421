import React from 'react'

function TenderText7() {
  return (
    <div>
        <embed src="\pdfs\EAT1.1.4.1_RA-V_Adjustable_radial_outlet_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default TenderText7