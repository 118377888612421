import React from 'react'

function TenderText8() {
  return (
    <div>
        <embed src="\pdfs\EAT1.1.4.2_RA-V2_Adjustable_radial_outlet_with_core_tube_kr (1).pdf" width="100%" height="2100px" />
    </div>
  )
}

export default TenderText8