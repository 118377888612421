import React from 'react'

function RadialOutlet6() {
  return (
    <div>
        <embed src="\pdfs\E1.1.3.3_RA-N3_Radial_outlet_04.2013_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default RadialOutlet6