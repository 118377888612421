import React from 'react'

function RadialOutlet() {
    return (
        <div>
            <embed src="\pdfs\E1.1.3.1_RA-N_Radial_outlet_02.2016_kr.pdf" width="100%" height="2100px" />
        </div>
    )
}

export default RadialOutlet