import React from 'react'

function TenderText5() {
  return (
    <div>
         <embed src="\pdfs\EAT1.1.3.2_RA-N2_Radial_outlet_kr (1).pdf" width="100%" height="2100px" />
    </div>
  )
}

export default TenderText5