import React, { useEffect, useState } from 'react';
import '../Scrolltotop/scrolltotop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

function ScrollToTopButton() {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        const viewportHeight = window.innerHeight;
        const scrollPositionToShow = viewportHeight / 2;
        if (window.pageYOffset > scrollPositionToShow) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {isVisible &&
                <button className="btn btn-primary scroll-to-top" onClick={scrollToTop}>
                    <FontAwesomeIcon icon={faChevronUp} />
                </button>
            }
        </>
    );
}

export default ScrollToTopButton;
