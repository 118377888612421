import React from 'react'

function MicrodrallMd() {
  return (
    <div>
        <embed src="\pdfs\E1.1.5_MD_Microdrall_05.2014_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default MicrodrallMd