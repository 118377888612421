import React from 'react'

function RadialOutlet5() {
    return (
        <div>
            <embed src="\pdfs\E1.1.3.2_RA-N2_Radial_outlet_04.2013_kr.pdf" width="100%" height="2100px" />
        </div>
    )
}

export default RadialOutlet5