import React from 'react'

function RadialSlot3() {
    return (
        <div>
            <embed src="\pdfs\E1.1.2.2_RL-C_Radial_slot_outlet_01.2018-kr.pdf" width="100%" height="2100px" />

        </div>
    )
}

export default RadialSlot3