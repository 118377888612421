import React from 'react'

function TenderText6() {
    return (
        <div>
            <embed src="\pdfs\EAT1.1.3.3_RA-N3_Radial_outlet_kr.pdf" width="100%" height="2100px" />
        </div>
    )
}

export default TenderText6