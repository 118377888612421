import React from 'react'

function CoreTube() {
  return (
    <div>
        <embed src="\pdfs\E1.1.4.2_RA-V2_Adjustable_radial_outlet_with_core_tube_01.2014_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default CoreTube