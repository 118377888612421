import React from 'react';
import '../Privacy/privacy.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ScrollToTopButton from '../Scrolltotop/Scrolltotop';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Privacy() {
    return (
        <>
            <Helmet>
                <title>BSL-3 | BSL-4</title>
                <meta name="description" content=" " />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="BSL-3 | BSL-4" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.scientechnology.com/privacy" hreflang="en-in" />
                <link rel="canonical" href="https://www.scientechnology.com/privacy" />
            </Helmet>
            <section id='privacy'>
                <Header />
                <div className='container' style={{ width: "75%", margin: "auto", marginTop: "100px" }}>
                    <h1 className='privacy_title'>Privacy Statement</h1>
                    <h3>Protection of data privacy: Your right – our obligation</h3>
                    <p>You can count on us to protect the privacy of your personal data... because protecting your privacy when we process your data is a matter of importance to Krantz GmbH that is taken into consideration in all of our business processes.</p>
                    <p>We‘d therefore like to take this opportunity to explain to you the guidelines that we apply to the processing of personal data. The present statement and any further information that may be pertinent to the processing and use of your personal data will be provided at any location on this site where we ask you to supply such data. </p>

                    <h6 className='privacy_sub_title'>1. Information regarding the gathering of personal data</h6>
                    <p>1.1 The protection of personal data is important to Krantz GmbH. Therefore, the Krantz GmbH company operates these websites as the responsible body in accordance with Art. 4 Number 7 GDPR.</p>
                    <p>1.2 You can contact our data protection officer by post at the following address:</p>
                    <p>STEAG GmbH<br />
                        Group data protection officer<br />
                        Erwin Wolff<br />
                        Rüttenscheider Straße 1-3<br />
                        45128 Essen<br />
                        + 49 201 801-4334<br />
                        <Link className='privacy_link'>datenschutz@steag.com</Link></p>

                    <h6 className='privacy_sub_title'>2. Gathering of personal data when visiting our website</h6>
                    <p>2.1 You can use virtually the whole of the Internet service of Krantz GmbH without us requiring personal data from you. Only a small number of services which you can find on our websites require the provision of personal data in order for you to be able to use them.</p>
                    <p>2.2 Should you use the Internet service of Krantz GmbH, technical access data is automatically recorded and evaluated by the Internet server (web server) of Krantz GmbH. However, this data cannot be allocated to a specific person; in such a case, the individual user remains anonymous.</p>
                    <p>The data which is recorded includes:</p>
                    <ul className='privacy_disc'>
                        <li>IP address</li>
                        <li>Date and time of enquiry</li>
                        <li>Time zone difference to Greenwich Mean Time (GMT)</li>
                        <li>Content of the request (concrete page)</li>
                        <li>Access status/HTTP status code</li>
                        <li>Respective amount of data transferred</li>
                        <li>Website from which the request originates</li>
                        <li>Browser</li>
                        <li>Operating system and its interface</li>
                        <li>Language and version of the browser software</li>
                    </ul>
                    <p>When individual Internet sites are accessed, this data is transferred to our web servers by your Internet browser. The legal basis is Art. 6 Paragraph 1 Letter f) of the GDPR.</p>
                    <p>We use this technical access information to constantly improve the attractiveness and users' experience of our websites and their contents and to identify potential technical issues in our internet presence.</p>
                    <p>2.3 When you get in touch with us via a contact form, you consent that we save the data you provided (for example your email address, name, telephone number), so that we can answer your questions. Once your enquiry has been processed, your data will be deleted.</p>
                    <p>2. 4 Duration of the storage of personal data</p>
                    <p>We delete your personal data as soon as they are no longer necessary for the above-mentioned purposes, unless statutory obligations to retain data preclude deletion.</p>

                    <h6 className='privacy_sub_title'>3. Cookies</h6>
                    <p>In certain areas of our Internet service, we also use cookies. Cookies are small data elements which an Internet server can send to your computer. For the duration of your visit to our Internet service, your computer can be identified by such cookies or the use of our Internet pages can be made easier for you. We do not use cookies in order to collect personal data. It is possible for you to set your Internet browser in such a way that you are informed of the acceptance or rejection of cookies. You can for example find out more about this in the assistance system of your Internet browser. </p>

                    <h6 className='privacy_sub_title'>4. Statistical evaluations with Matomo</h6>
                    <p>Our website uses Matomo, an open source software for the statistical evaluation of user access (<Link className='privacy_link'>http://de.matomo.org</Link>). The information regarding use which is generated by the cookies is transferred to our server and saved for use analysis purposes, which enables us to optimise our websites. During this process, your IP address is immediately anonymised, so that you remain anonymous to us as a user. The server on which the statistical data is saved is located in our computer centre in Essen, Germany. Should you not agree to the saving and evaluation of this data connected to your visit, you can then object to the saving and use at any time by clicking below. In this case, a so-called opt out cookie will be deposited in your browser, which means that Matomo will not gather any session data. Note: Should you delete your cookies, this means that the opt out cookie is also deleted and needs to be activated by you once again.</p>

                    <h6 className='privacy_sub_title'>5. Right of objection §15 Abs. 3 TMG</h6>
                    <p>You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website. Furthermore you can prevent Google’s collection and use of data (cookies and IP address) by downloading and installing the browser plug-in available under <Link className='privacy_link'>https://tools.google.com/dlpage/gaoptout?hl=en-GB</Link>. By using a so called Block-Cookie you can refuse to any kind of analytics from this website: <Link className='privacy_link'>https://tools.google.com/dlpage/gaoptout?hl=de</Link>. </p>

                    <h6 className='privacy_sub_title'>6. Objection or revocation of consent to the processing of your data</h6>
                    <p>6.1 Should you have issued your consent to the processing of your data, you can revoke it at any time. Such a revocation affects the lawfulness of the processing of your personal data, once you have declared this to us.</p>
                    <p>6.2 Should we base the processing of your personal data on the balancing of interests, you can raise an objection to the processing. This is the case in particular if the processing is not necessary to fulfil a contract with you, which is set out by us in the description of the functions below. When exercising such a right of objection, we would kindly request that you explain the reasons why we should not carry out the processing of your personal data. In case of a justified objection, we will check the facts and either suspend or adjust the data processing or explain to you the mandatory protectable reasons by reason of which we will continue the processing.</p>
                    <p>6.3 It goes without saying that you can object to the processing of your personal data for advertising and data analysis purposes. You can inform us via the contact details below of your objection to advertising: <Link className='privacy_link'>info@krantz.de</Link>  </p>

                    <h6 className='privacy_sub_title'>7. Your rights</h6>
                    <p>7.1 You have the following rights vis-à-vis us regarding your personal data:</p>
                    <ul className='privacy_disc'>
                        <li>Right to information,</li>
                        <li>Right to correction or deletion,</li>
                        <li>Right to limitation of processing,</li>
                        <li>Right to raise an objection to the processing,</li>
                        <li>Right to data transferability.</li>
                    </ul>
                    <p>7.2 You also have the right to complain to a data processing supervisory authority about the processing of your personal data by us. </p>
                    <hr className='contact_hr' />
                </div>
            </section>
            <Footer />
            <ScrollToTopButton />
        </>
    )
}

export default Privacy