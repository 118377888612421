import React from 'react'

function TenderText10() {
  return (
    <div>
        <embed src="\pdfs\EAT1.1.6.2_DD-VK_Variable_twist_outlet_with_core_tube_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default TenderText10