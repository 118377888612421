import React from 'react'

function TenderText4() {
  return (
    <div>
        <embed src="\pdfs\EAT1.1.3.1_RA-N_Radial_outlet_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default TenderText4