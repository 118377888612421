import React from 'react';
import Header from '../../Components/Header/Header';
import '../News/news.css';
import { Link } from 'react-router-dom';
import news1 from '../../images/news/news_1.jpg';
import news2 from '../../images/news/news_2.jpg';
import news3 from '../../images/news/news_3.jpg';
import news4 from '../../images/news/news_4.jpg';
import news5 from '../../images/news/news_5.jpg';
import news6 from '../../images/news/news_6.jpg';
import event1 from '../../images/news/events_1.jpg';
import event2 from '../../images/news/events_2.jpg';
import Footer from '../../Components/Footer/Footer';
import ScrollToTopButton from '../../Components/Scrolltotop/Scrolltotop';
import { Helmet } from 'react-helmet';

function News() {
  return (
    <>
      <Helmet>
        <title>Nuclear Carbon Filters | Carbon filter housings</title>
        <meta name="description" content=" " />
        <meta name="robots" content="index, follow" />
        <meta name="geo.placename" content="India" />
        <meta http-equiv="content-language" content="in" />
        <meta name="geo.region" content="india" />
        <meta property="og:title" content="Nuclear Carbon Filters | Carbon filter housings" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
        <meta property="og:locale" content="en-in" />
        <link rel="alternate" href="https://www.scientechnology.com/news" hreflang="en-in" />
        <link rel="canonical" href="https://www.scientechnology.com/news" />
      </Helmet>
      <section id='news_events'>
        <div className='container' style={{ width: "75%", margin: "auto", marginTop: "100px" }}>
          <Header />
          <h2 className='news_head'>Fairs / Exhibitions</h2>
          <p className='news-subpara'>Krantz is organizing seminars and workshops for expert audiences in Aachen (Germany).<br />
            Krantz attend various international fairs and events.Please refer to the Krantz website where you can meet them <Link className='news_link_krantz' to="https://www.krantz.de/en/" target='blank'>www.krantz.de/en</Link></p>
          <hr className='news_hr' />
          <div className='col-md-8'>
            <img className='img-fluid' src={news1} alt="news" />
            <h4 className='news-arabtitle'>Arab Lab Exhibition 2018</h4>
            <p className='news-arabpara'>18 March, 2018</p>
          </div><br /><br />
          <div className='col-md-8'>
            <img className='img-fluid' src={news2} alt="news" />
            <h4 className='news-arabtitle'>Arab Lab Exhibition 2017</h4>
            <p className='news-arabpara'>20 March, 2017</p>
          </div><br /><br />
          <div className='col-md-8'>
            <img className='img-fluid' src={news3} alt="news" />
            <h4 className='news-arabtitle'>Arab Lab Exhibition 2016</h4>
            <p className='news-arabpara'>20 March, 2016</p>
          </div><br /><br />
          <div className='col-md-8'>
            <img className='img-fluid' src={news4} alt="news" />
            <h4 className='news-arabtitle'>Arab Lab 2015</h4>
            <p className='news-arabpara'>23 March, 2015</p>
          </div><br /><br />
          <div className='col-md-8'>
            <img className='img-fluid' src={news5} alt="news" />
            <h4 className='news-arabtitle'>Arab LAB 2014</h4>
            <p className='news-arabpara'>17 March, 2014</p>
          </div><br /><br />
          <div className='col-md-8'>
            <img className='img-fluid' src={news6} alt="news" />
            <h4 className='news-arabtitle'>Krantz-IMTECH</h4>
            <p className='news-arabpara'>03 August, 2013</p>
          </div><br /><br />
          <h3 className='event_heading'>Events</h3>
          <hr className='news_hr' />
          <div className='row'>
            <div className='col-md-4'>
              <img className='img-fluid' src={event1} alt="events" />
            </div>
            <div className='col-md-8 event_content'>
              <h4 className='heading mt-2'>"It's all about collaboration!"</h4>
              <p>Our Head of Sales International Marco Sauder and our Managing Director are meeting up with our partner Thomas Philip in India to grow knowledge and business in the Indian market. More details to follow very soon...</p>
              <Link to="https://www.linkedin.com/feed/update/urn:li:activity:6511607706393157633/" target='blank'><button className='event_btn'>Read More</button></Link>
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='col-md-4'>
              <img className='img-fluid' src={event2} alt="events" />
            </div>
            <div className='col-md-8 event_content'>
              <h4 className='heading mt-2'>Krantz - global as never before</h4>
              <p>Our international sales meeting began with a game of bowling as our foreign representatives from Australia, Belgium, China, France, Great Britain,...</p>
              <Link to="https://www.krantz.de/en/news/detail/?tx_news_pi1%5Baction%5D=detail&tx_news_pi1%5Bcontroller%5D=News&tx_news_pi1%5Bnews%5D=358&cHash=c4682c630115a5c7e8f1be83c326b873" target='blank'><button className='event_btn'>Read More</button></Link>
            </div>
          </div>

          <hr style={{ marginTop: "70px" }} />
        </div>
      </section>
      <Footer />
      <ScrollToTopButton />
    </>
  )
}

export default News