import React from 'react'

function TenderText() {
  return (
    <div>
        <embed src="\pdfs\EAT1.1.1_DD-N_Twist_outlet_kr.pdf" width="100%" height="2100px" />
    </div>
  )
}

export default TenderText