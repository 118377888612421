import React from 'react'

function HighIntegrity() {
  return (
    <>
    <embed src="/pdfs/Corona-Filter.pdf" width="100%" height="2100px" />
    </>
  )
}

export default HighIntegrity