import React from 'react'

function AdjustableRadial() {
    return (
        <div>
            <embed src="\pdfs\E1.1.4.1_RA-V_Adjustable_radial_outlet_04.2013_kr.pdf" width="100%" height="2100px" />
        </div>
    )
}

export default AdjustableRadial